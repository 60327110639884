import React from "react";
import type { GatsbyBrowser } from "gatsby";
import Root from "./src/layouts/partials/root";

export const wrapRootElement: GatsbyBrowser["wrapRootElement"] = ({
  element,
}) => {
  return <Root>{element}</Root>;
};

/* Fix: 'netlify-identity-widget' module is missing for registration */

var routes = /(confirmation|invite|recovery|email_change)_token=([^&]+)/;
var errorRoute = /error=access_denied&error_description=403/;
var accessTokenRoute = /access_token=/;

export const onInitialClientRender = () => {
  var hash = (document.location.hash || "").replace(/^#\/?/, "");
  if (
    routes.test(hash) ||
    errorRoute.test(hash) ||
    accessTokenRoute.test(hash)
  ) {
    const netlifyIdentityWidget = require("netlify-identity-widget");
    netlifyIdentityWidget.on("init", function (user) {
      if (!user) {
        netlifyIdentityWidget.on("login", function () {
          document.location.href = "/admin/";
        });
      }
    });
    netlifyIdentityWidget.init();
  }
};

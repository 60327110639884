import React from "react";
import { Icon, IconProps } from "@/icons/partials/base";

export const SquareFacebookIcon = ({ ...props }: IconProps) => (
  <Icon height="24" width="21" viewBox="0 0 448 512" {...props}>
    <path
      fill="currentColor"
      d="m64 32c-35.3 0-64 28.7-64 64v320c0 35.3 28.7 64 64 64h98.2v-145.8h-52.8v-78.2h52.8v-33.7c0-87.1 39.4-127.5 125-127.5 16.2 0 44.2 3.2 55.7 6.4v70.8c-6-.6-16.5-1-29.6-1-42 0-58.2 15.9-58.2 57.2v27.8h83.6l-14.4 78.2h-69.3v145.8h129c35.3 0 64-28.7 64-64v-320c0-35.3-28.7-64-64-64z"
    />
  </Icon>
);

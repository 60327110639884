import "@resources/styles/app.css";
import React from "react";
import { domAnimation, LazyMotion } from "framer-motion";
import RunnersVideo from "@/components/runners-video";
import Footer from "@/layouts/partials/footer";
import Header from "@/layouts/partials/header";

interface LayoutProps {
  children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => (
  <LazyMotion features={domAnimation}>
    <div className="flex relative z-10 flex-col items-stretch min-h-screen">
      <Header />
      <div className="flex flex-col items-stretch justify-center flex-1">
        <main>{children}</main>
      </div>
      <Footer />
    </div>
    <RunnersVideo />
  </LazyMotion>
);

export default Layout;

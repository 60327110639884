import React, { useEffect, useState } from "react";
import { m as motion, useScroll, useTransform } from "framer-motion";
import { graphql, useStaticQuery } from "gatsby";
import VideoMp4 from "@resources/video/runners.mp4";
import VideoWebm from "@resources/video/runners.webm";

const RunnersVideo: React.FC = () => {
  const { scrollY } = useScroll();
  const [loadVideo, setLoadVideo] = useState(false);

  const loadVideoCallback = () => setLoadVideo(true);

  useEffect(() => {
    if (document.readyState === "complete") {
      loadVideoCallback();
    } else {
      window.addEventListener("load", loadVideoCallback);
    }

    return () => window.removeEventListener("load", loadVideoCallback);
  }, []);

  const y = useTransform(() => scrollY.get() * -0.5);

  const { file } = useStaticQuery<Queries.RunnersVideoQuery>(graphql`
    query RunnersVideo {
      file(absolutePath: { glob: "**/video/runners.jpg" }) {
        childImageSharp {
          fixed(width: 720, height: 405) {
            srcWebp
          }
        }
      }
    }
  `);

  return (
    <motion.div
      className="fixed inset-x-0 top-0 z-0 overflow-hidden opacity-30 gradient-mask"
      style={{ y }}
    >
      <video
        aria-hidden
        className="block max-w-none w-[150%] translate-x-[-25%] h-auto sm:w-full sm:translate-x-0"
        poster={file?.childImageSharp?.fixed?.srcWebp ?? undefined}
        autoPlay
        muted
        playsInline
        loop
      >
        {loadVideo && (
          <>
            <source src={VideoMp4} type="video/mp4" />
            <source src={VideoWebm} type="video/webm" />
          </>
        )}
      </video>
      <div className="absolute inset-0 z-10 pointer-events-auto" />
    </motion.div>
  );
};

export default RunnersVideo;

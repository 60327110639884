import React, { useMemo, useContext, useState } from "react";

export interface ThemeContextProps {
  headerHeight: number;
  setHeaderHeight: (height: number) => void;
}

const themeContextDefaults = {
  headerHeight: 0,
};

const ThemeContext = React.createContext<ThemeContextProps>(
  themeContextDefaults as ThemeContextProps,
);

function ThemeProvider({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  const [headerHeight, setHeaderHeight] = useState<number>(
    themeContextDefaults.headerHeight,
  );

  const value = useMemo(
    () => ({
      headerHeight,
      setHeaderHeight,
    }),
    [headerHeight, setHeaderHeight],
  );

  return (
    <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
  );
}

export default ThemeContext;

export { ThemeProvider };

export const useTheme = () => useContext(ThemeContext);

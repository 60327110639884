import React, { useEffect, useRef } from "react";

export interface HamburgerProps {
  label?: string;
  active?: boolean;
  onClick: (active: boolean) => void;
}

const Hamburger: React.FC<
  HamburgerProps & React.HTMLAttributes<HTMLButtonElement>
> = ({
  label = undefined,
  onClick,
  active = false,
  id,
  tabIndex = 0,
  ...any
}) => {
  const svgAnimateActiveRef = useRef<(SVGAnimateElement | null)[]>([]);
  const svgAnimateInactiveRef = useRef<(SVGAnimateElement | null)[]>([]);

  function handleKeyPress(e: React.KeyboardEvent) {
    if (e.key !== " ") return;

    e.preventDefault();
    onClick(!active);
  }

  useEffect(() => {
    (active ? svgAnimateActiveRef : svgAnimateInactiveRef).current.forEach(
      (current) => {
        if (current) current.beginElement();
      },
    );
  }, [active]);

  return (
    <button
      type="button"
      className="relative inline-block group z-1 text-inherit"
      tabIndex={tabIndex}
      onClick={() => onClick(!active)}
      onKeyDown={(e) => handleKeyPress(e)}
      id={id}
      {...any}
    >
      <svg
        aria-hidden="true"
        width="32"
        height="32"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g fill="none" fillRule="evenodd">
          <path d="M3,8 29,8" stroke="currentColor" strokeWidth="2">
            <animate
              ref={(ref: SVGAnimateElement) => {
                svgAnimateActiveRef.current[0] = ref;
              }}
              fill="freeze"
              begin="indefinite"
              attributeName="d"
              dur="250ms"
              to="M7,7 25,25"
              keySplines="0.25 0 0.25 1; 0.25 0 0.25 1"
              calcMode="spline"
            />
            <animate
              ref={(ref: SVGAnimateElement) => {
                svgAnimateInactiveRef.current[0] = ref;
              }}
              fill="freeze"
              begin="indefinite"
              attributeName="d"
              dur="250ms"
              to="M3,8 29,8"
              keySplines="0.25 0 0.25 1; 0.25 0 0.25 1"
              calcMode="spline"
            />
          </path>
          <path d="M3,16 29,16" stroke="currentColor" strokeWidth="2">
            <animate
              ref={(ref: SVGAnimateElement) => {
                svgAnimateActiveRef.current[1] = ref;
              }}
              fill="freeze"
              begin="indefinite"
              attributeName="d"
              dur="120ms"
              to="M5,16 23,16"
              keySplines="0.25 0 0.25 1; 0.25 0 0.25 1"
              calcMode="spline"
            />
            <animate
              ref={(ref: SVGAnimateElement) => {
                svgAnimateActiveRef.current[2] = ref;
              }}
              fill="freeze"
              begin="indefinite"
              attributeName="opacity"
              dur="250ms"
              to="0"
            />
            <animate
              ref={(ref: SVGAnimateElement) => {
                svgAnimateInactiveRef.current[1] = ref;
              }}
              fill="freeze"
              begin="indefinite"
              attributeName="d"
              dur="250ms"
              to="M3,16 29,16"
              keySplines="0.25 0 0.25 1; 0.25 0 0.25 1"
              calcMode="spline"
            />
            <animate
              ref={(ref: SVGAnimateElement) => {
                svgAnimateInactiveRef.current[2] = ref;
              }}
              fill="freeze"
              begin="indefinite"
              attributeName="opacity"
              dur="350ms"
              to="1"
            />
          </path>
          <path d="M3,24 29,24" stroke="currentColor" strokeWidth="2">
            <animate
              ref={(ref: SVGAnimateElement) => {
                svgAnimateActiveRef.current[3] = ref;
              }}
              fill="freeze"
              begin="indefinite"
              attributeName="d"
              dur="250ms"
              to="M7,25 25,7"
              keySplines="0.25 0 0.25 1; 0.25 0 0.25 1"
              calcMode="spline"
            />
            <animate
              ref={(ref: SVGAnimateElement) => {
                svgAnimateInactiveRef.current[3] = ref;
              }}
              fill="freeze"
              begin="indefinite"
              attributeName="d"
              dur="250ms"
              to="M3,24 29,24"
              keySplines="0.25 0 0.25 1; 0.25 0 0.25 1"
              calcMode="spline"
            />
          </path>
        </g>
      </svg>
      <span className="sr-only">{label ?? "Navigation"}</span>
    </button>
  );
};

export default Hamburger;

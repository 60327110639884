import React, { forwardRef } from "react";
import type { GatsbyLinkProps } from "gatsby";
import { Link as GatsbyLink } from "gatsby";

export type LinkProps = Omit<GatsbyLinkProps<unknown>, "ref">;

const Link = forwardRef<HTMLAnchorElement, LinkProps>(
  (
    { children, to, className: classNameProp, onClick, ...any },
    ref,
  ): JSX.Element => {
    const classNames: string[] = [];

    if (classNameProp) classNames.push(classNameProp);

    const className = classNames.join(" ");

    if (/^[/#](?!\/)/.test(to)) {
      return (
        <GatsbyLink
          to={to}
          className={className}
          innerRef={ref}
          onClick={(e) => {
            if (to.includes("#")) {
              const target = document.getElementById(to.split("#")[1]);

              if (target) {
                e.preventDefault();
                target.scrollIntoView({ behavior: "smooth" });
              }
            }

            if (onClick) onClick(e);
          }}
          {...any}
        >
          {children}
        </GatsbyLink>
      );
    }

    return (
      <a
        href={to}
        target="_blank"
        rel="noopener noreferrer"
        className={className}
        ref={ref}
        onClick={onClick}
        {...any}
      >
        {children}
      </a>
    );
  },
);

export default Link;

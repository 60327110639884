exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-ablauf-tsx": () => import("./../../../src/pages/ablauf.tsx" /* webpackChunkName: "component---src-pages-ablauf-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-impressionen-tsx": () => import("./../../../src/pages/impressionen.tsx" /* webpackChunkName: "component---src-pages-impressionen-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-sponsoren-tsx": () => import("./../../../src/pages/sponsoren.tsx" /* webpackChunkName: "component---src-pages-sponsoren-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */)
}


import * as React from "react";
import { cn } from "@/helpers/classNames";

export interface ContainerProps extends React.HTMLAttributes<HTMLDivElement> {
  el?: "div" | "section" | "aside" | "header";
}

const Container: React.FC<ContainerProps> = ({
  el = "div",
  children = undefined,
  className,
  ...props
}) => {
  const Wrapper = el;

  return (
    <Wrapper className={cn("container", className)} {...props}>
      {children}
    </Wrapper>
  );
};

export default Container;

import React from "react";

export type IconProps = React.SVGAttributes<SVGElement>;

export const Icon = ({ children = undefined, ...props }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="16"
    width="16"
    viewBox="0 0 512 512"
    {...props}
  >
    {children}
  </svg>
);

import React from "react";
import { ThemeProvider } from "@/contexts/theme";

type RootProps = {
  children: React.ReactNode;
};

const Root: React.FC<RootProps> = ({ children }) => (
  <ThemeProvider>{children}</ThemeProvider>
);

export default Root;

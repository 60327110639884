import React from "react";
import Container from "@/components/container";
import Link from "@/components/link";
import { SquareFacebookIcon } from "@/icons/square-facebook";
import { SquareInstagramIcon } from "@/icons/square-instagram";

const Footer: React.FC = () => (
  <footer>
    <Container className="flex flex-col font-medium text-md mt-lg py-md text-white/60 gap-base md:gap-xs md:justify-between md:text-lg">
      <nav className="flex flex-col md:flex-row gap-base">
        <ul className="flex md:items-center gap-base grow">
          <li>
            <Link
              to="https://www.instagram.com/sauerlaender_firmenlauf/"
              aria-label="Bei Instagram folgen"
            >
              <SquareInstagramIcon />
            </Link>
          </li>
          <li>
            <Link
              to="https://m.facebook.com/sauerlaenderfirmenlauf"
              aria-label="Bei Facebook folgen"
            >
              <SquareFacebookIcon />
            </Link>
          </li>
        </ul>
        <ul className="flex flex-col md:flex-row md:items-center gap-base">
          <li>
            <Link
              to={`mailto:info@sauerlaender-firmenlauf.de?subject=${encodeURI(
                "Kontaktaufnahme Sauerländer AOK Firmenlauf 2024",
              )}&body=${encodeURI(
                "Liebes Firmenlauf-Team, ich habe folgende Frage:",
              )}`}
            >
              Kontakt
            </Link>
          </li>
          <li>
            <a href="/teilnahmebedingungen.pdf" type="application/pdf">
              Teilnahmebedingungen
            </a>
          </li>
          <li>
            <Link to="/datenschutz">Datenschutz</Link>
          </li>
          <li>
            <Link to="/impressum">Impressum</Link>
          </li>
        </ul>
      </nav>
    </Container>
  </footer>
);

export default Footer;
